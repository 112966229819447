import React from 'react';
import Layout from '../components/layout';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { graphql } from 'gatsby';

const Post = ({ data }) => {
  const StyledService = styled.div`
    .top-bg {
      height: 30rem;
      position: relative;
      .gatsby-image-wrapper {
        height: 100%;
      }
    }
    .container {
      margin: 0 auto 6rem;
      h1 {
        margin-top: -5rem;
        background: ${props => props.theme.colors.org};
        color: #ffffff;
        display: inline-block;
        padding: 2rem;
      }
      .content {
        width: 75%;
        font-family: ${props => props.theme.fonts.sec};
        p {
          font-size: 1.8rem;
          line-height: 2.5rem;
        }
      }
    }
  `;
  return (
    <Layout>
      <StyledService>
        <div className="top-bg">
          <Img fluid={data.wordpressWpNews.acf.news_image.localFile.childImageSharp.fluid} />
        </div>
        <div className="container">
          <h1>{data.wordpressWpNews.acf.news_title}</h1>
          <div className="content" dangerouslySetInnerHTML={{ __html: data.wordpressWpNews.acf.content }} />
        </div>
      </StyledService>
    </Layout>
  );
};

export const data = graphql`
  query($slug: String!) {
    file(relativePath: { eq: "services.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2560) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    wordpressWpNews(slug: {eq: $slug}) {
      acf {
        news_title
        news_image {
          localFile {
            childImageSharp {
              fluid(maxWidth: 2560) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        excerpt
        content
      }
    }
  }
`;

export default Post;
